@import "../../assets/scss/variables.scss";
@import "../../assets/scss/allModal.scss";

.register-link {
    text-decoration: underline;
}
.overFlow{
	overflow: auto;
}
.loginBotton{
    padding: 40px 0;
}
.auth-card{
    padding: 40px;
}
.MuiBox-root.rememberOut{
    margin-top: -10px;
    margin-bottom: 15px !important;
}

@media (max-width:767px) {
    .MuiBox-root.rememberOut{
        margin-top: -12px;
        margin-bottom: 10px !important;
    }
}

