@import "./../../assets/scss/variables.scss";

.landing-page {
    .landingHead {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        align-items: center;
    }

    .topLinks {
        display: flex;

        .MuiButton-label {
            font-weight: 400;
        }

        .MuiButton-text:last-child {
            padding-right: 0px !important;
        }
    }

    .topLinks .MuiButton-text {
        display: flex;
        align-items: center;
    }

    .topLinks .MuiButton-text svg {
        margin-right: 7px;
        font-size: 18px;
        margin-top: -1px;
    }

    .topLinks {
        .MuiButton-root {
            height: 39px !important;
            padding: 0 21px !important;

            .MuiButton-label {
                padding-top: 3px !important;
            }
        }
    }

    .chefImgBox {
        padding-bottom: 60px;
        margin-top: -20px;

        img {
            max-width: 100% !important;
        }
    }

    .sectionBox {
        padding: 35px 0 45px !important;
    }

    .sectionBoxImg {
        padding: 35px 0 0 !important;
    }

    .step-box {
        text-align: center;
    }

    .step-box h6 {
        margin: 10px 0;
    }

    .step-box p {
        max-width: 72%;
        margin: 0px auto;
    }

    .step-box img { 
        margin-bottom: 7px;
    }

    .landFooter p {
        border-top: 1px dashed #ccc;
        padding: 30px 0;
        color: #969696 !important;
        font-size: 13px !important;
    }

    h5.mainHeading {
        font-weight: 500 !important;
        font-size: 38px !important;
        letter-spacing: 1px;
        margin-top: 60px !important;
    }

    h5.mainHeading span {
        font-size: 38px !important;
        letter-spacing: 1px;
    }

    .subLine {
        padding: 9px 0 32px !important;
        font-weight: 300 !important;
    }

    .SectionHead {
        font-weight: 500 !important;
        font-size: 28px !important;
        letter-spacing: 0.2px;
    }

    .SectionHead span {
        font-size: 28px !important;
        letter-spacing: 0.2px;
        font-weight: 500 !important;
    }

    .bannButton {
        padding-bottom: 90px !important;
    }

    .landing-page-content {
        position: relative;
    }

    .step-box-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 2rem;
    }

    .recipe-box-container {
        display: flex;
        margin-top: 2rem;
        overflow-x: auto;
        justify-content: center;

    }

    .popular-recipe-container,
    .get-in-touch-container {
        background: #f2f2f2;
    }
    .landFooterOut {
        background: #f2f2f2 !important;
    }

    .recipe-box {
        width: 300px;
        padding: 10px;
        text-align: center;
        .chefOut{
            background: #fff;
            height: 180px;
            width: 180px;
            margin: 0px auto;
            border-radius: 50%;
            overflow: hidden;
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        .recipe-box-title {
            margin-top: 22px;
            font-weight: bold;
            text-align: center;
            font-size: 16px !important;
        }

        .recipe-box-by {
            color: green;
        }
    }

    .chefs-banner {
        max-width: 100%;
    }

    .first-input,
    .second-input,
    .single-input {
        width: 100%;
        margin: 10px 0 !important;
    }

    .MuiOutlinedInput-input {
        background: white !important;
        border-radius: inherit;
    }

    .contForm .MuiButton-fullWidth {
        width: 150px !important;
    }

    .MuiOutlinedInput-multiline {
        padding: 0;

        textarea {
            padding: 18.5px 14px;
        }
    }
    .chefImgBox video{
        width: 780px;
        height: auto !important;
        background: url(../../assets/images/videoBg.jpg) ; 
    }
    @media only screen and (min-width: 768px) {
        .step-box-container {
            display: flex;
            justify-content: center;
            flex-direction: row;
        }

        .step-box {
            width: 350px;
        }

        .first-input,
        .second-input {
            width: 48% !important;
        }

        .input-container {
            display: flex;
            justify-content: space-between;
        }

        .landing-content-container {
            max-width: 700px;
            margin: 0 auto !important;
        }
    }

}
video {
    max-width: 780px;
}

@media (max-width:990px) {
    video {
        max-width: 100%;
    }
    .landing-page {
        .landingHead {
            padding: 15px 0 !important;
        }
    }

    .landing-page .recipe-box-container {
        justify-content: left;
    }

    .landing-page .recipe-box {
        min-width: 180px;
    }

    .landing-page .recipe-box .chefOut {
        height: 130px;
        width: 130px;
    }
    
}

@media (max-width:767px) {
    .topLinks {
        .MuiButton-text:last-child {
            display: none !important;
        }
    }
    .landing-page .chefImgBox {
        padding-bottom: 40px;
        margin-top: -25px;
    }
    
    .landing-page h5.mainHeading {
        font-size: 28px !important;
        letter-spacing: 0.5px;
        margin-top: 20px !important;
    }

    .landing-page h5.mainHeading span {
        font-size: 28px !important;
        letter-spacing: 0.5px;
    }

    .landing-page .subLine {
        padding: 0 15px 24px !important;
        font-weight: 300 !important;
    }

    .landing-page .bannButton {
        padding-bottom: 50px !important;
    }

    .landing-page .sectionBox {
        padding: 20px 0 35px !important;
    }

    .landing-page .step-box-container {
        margin-top: 10px;
    }

    .step-box {
        margin-bottom: 35px !important;
    }

    .step-box-container .step-box:last-child {
        margin-bottom: 1px !important;
    }

    .landing-page .step-box p {
        max-width: 80%;
    }

    .landing-page .step-box img {
        width: 87% !important;
        margin-bottom: 0;
    }

    .landing-page .SectionHead {
        font-size: 23px !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .landing-page .SectionHead span {
        font-size: 23px !important;
    }

    .landing-page .sectionBoxImg {
        padding: 15px 0 0 !important;
    }

    .landing-page .chefImgBox img {
        margin-top: 0px;
    }

    .landing-page .landFooter p {
        padding: 20px 0;
    }
}