@import "../../assets/scss/variables.scss";
@import "../../assets/scss/allModal.scss";

.subscribeOut{
    margin-top: -25px;
  
}

.cover-img {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.profileOut{
    position: relative;  
    height: 144px;
    width: 144px;
    z-index: 99;
    margin-top: 36px; 
}
.profileOut .MuiAvatar-root{
    margin: 0px;
}
.pageContainer span.MuiTypography-h5{ 
    font-weight: bold;
}
.subPriceOut p,
p.subPrice {
    font-weight: bold !important;
}
.midCard .MuiButton-fullWidth {
    max-width: 350px !important;
}
.leftButt{
    display: flex !important;
}
.midCard span.MuiTypography-root{
    border-radius: 34px !important;
    font-size: 17px !important;
    font-weight: bold;
    padding: 12px 32px !important;
}
.pageContainer{
    padding-bottom: 15px !important;
  }
  .pageContainer .MuiCard-root{
    -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
  }
  .pageContainer .recipe-card{
    -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
  }  
  .pageContainer .MuiCard-root .MuiCardContent-root{
    padding: 25px !important;
  }
  .pageContainer .MuiCard-root ul{
    margin-bottom: 0px;
  }
  .pageContainer .MuiCard-root ul li p{
    margin: 2px 0;
  }
  .LastCard{
    padding-bottom: 25px !important;
  }
  .recipe-nameplate .recipe-time{
    color: $green;
  }
  .subscribedBtOut{
      position: relative;
  }
  .subscribedBt{ 
    position: absolute;  
    top: -10px; 
    right: 0;
  }
  .sortOut{
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
  }
  
 
@media (max-width:600px) {
    .cover-banner {
        height: 160px !important;
    }
    .MuiBox-root-103 { 
         
    }
    .firstCard { 
        margin-top: 70px !important;
    }
    .subscribedBt{
        top: -135px;
    }

    .subscribedBt .MuiButton-root {
        height: 40px !important;
    }
    .subscribedBt .MuiButton-label{
        font-size: 13px !important;
    }
    .profileOut {
        height: 110px !important;
        width: 110px !important;
        margin-top: 48px !important;
    }
    .profileOut .MuiAvatar-circular{
        height: 110px !important;
        width: 110px !important;
    }
    .profileOut .makeStyles-large-23{
        height: 110px !important;
        width: 110px !important;
    }
    .profileOut .MuiBox-root.editIcon{
        margin-top: -3px !important;
        margin-right: -3px !important;
        width: 28px;
        height: 28px;
    }
    .profileOut .MuiBox-root.editIcon span{
        font-size: 14px !important;
    }
    .subPriceOut p,
    p.subPrice {
        font-weight: bold !important;
    }
    .pageContainer span.MuiTypography-h5{
        font-size: 17px !important;
        font-weight: bold;
        margin-bottom: 5px !important;
    }
    .pageContainer svg.share-icon{
        font-size: 17px !important;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .midCard{
        margin-top: 20px !important;
    }
    .LastCard{
        margin-top: 20px !important;
        padding-bottom: 10px !important;
    }
    .pageContainer .MuiCard-root .MuiCardContent-root {
        padding: 20px !important;
    }
    .midCard .MuiButton-fullWidth {
        max-width: 100% !important;
    }
    .recipe-name h6{
        font-size: 16px !important;
    }
    .recipe-name img{
        width: 15px !important;
    }
    .midCard span.MuiTypography-root { 
        font-size: 16px !important; 
        padding: 8px 30px;
    }

}

@media (max-width:479px) {
    .cover-banner {
        height: 120px !important;
    }
    .profileOut { 
        margin-top: 10px !important;
    }
    .recipe-name h6{
        font-size: 15px !important;
    }
    .subscribedBt{
        top: -130px;
    }
    
}    
