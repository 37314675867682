// Bg Color
$bglight: #F9F8F6;
$bgWhite: #ffffff;
$bgGrey: #b4b4b4;
$bgLightGrey: #edefef;
$bgRowLightGrey: #f8f8f8;
$bgGreen: #033732;
$bgLightGreen:#DCF4EB;
$bgGreenLight: #55B893;
$bgGreenDark: #226E67;
$bgRedDot: #fc3c3c;
$bgYelloDot: #FFC327;
$bgGreyBlack: #0B0B0B;
$bgDarkPink: #FE6F61;
$bgBlack: #000000;
$bgBlackLight:#626262;
$green:#40D44A;

// Color
$colorGreyBlack: #0B0B0B;
$colorDarkPink: #FE6F61;
$colorRed: #F70C0C;
$colorLightPink:#F65D5D;
$colorGreen: #033732;
$colorLightGreen: #DCF4EB;
$colorGrey: #E4E4E4;
$colorGreyLight: #CCCCCC;
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorGreyDark: #888888;
$colorLightGrey: #B8B8B8;
$colorYellow: #FFC327;
$colorBlue:#0C7DE4;
$colorLightBlack:#5E5E5E;
$colorGreyDarkLight:#83817E;


// Font Size
$fontSize8: 8px;
$fontSize10: 10px;
$fontSize12: 12px;
$fontSize13: 13px;
$fontSize14: 14px;
$fontSize15: 15px;
$fontSize16: 16px;
$fontSize18: 18px;
$fontSize20: 20px;
$fontSize22: 22px;
$fontSize24: 24px;
$fontSize25: 25px;
$fontSize28: 28px;
$fontSize30: 30px;
$fontSize32: 32px;
$fontSize40: 40px;
$fontSize48: 48px;
$fontSize56: 56px;

// Font waight
$fontWeight300: 300;
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
 
$primary-font-family: "Rotunda";
$secondary-font-family: "Nantes Trial";

h1{
	font-family: $secondary-font-family;
	font-weight: bold;
}
//css
.sectHeading{
	text-align: left;
	font-weight: 600 !important;
}
.leftText {
	text-align: left;
}
.rightText {
	text-align: right;
}
.CentText {
	text-align: center;
}
.padding0 {
	padding: 0;
}
.padding15 {
	padding: 15px;
}

.paddingTop {
	padding-top: 64px;
}
.paddLeft {
	padding-left: 15px;
}
.paddRight {
	padding-right: 15px;
}
.paddLeft0 {
	padding-left: 0px;
}
.paddRight0 {
	padding-right: 0px;
}
.margin0 {
	margin: 0;
}
.margin15 {
	margin: 15px;
}
.marLeft {
	margin-left: 15px;
}
.marRight {
	margin-right: 15px;
}
.marLeft0 {
	margin-left: 0px;
}
.marRight0 {
	margin-right: 0px;
}
.marBottom0 {
	margin-bottom: 0px !important;
}
a {
	text-decoration: none !important;
}
 
