.stripe-link {
    font-size: 14px;
    margin-bottom: 30px !important;
    margin-left: 0;

    p {
        color: blue !important;
    }
}
.myButtonStipr{
    background-color: #40D44A;    
    border-radius: 100px;
    display: inline-block;
    padding: 12px 40px;   
    margin-top: 10px !important; 
    text-transform: uppercase;

    p {
        color: #fff !important;
        font-weight: 500;
        letter-spacing: 1px;
    }

}
.MuiTypography-body1.noteText{
    font-size: 13px !important;
}