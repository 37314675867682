@import "./assets/scss/variables.scss";

body {
  margin: 0px !important;
  padding: 0px !important;
  font-family: $primary-font-family !important;
  font-size: $fontSize14 !important;
  background-color: $bgWhite !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.4px !important;
}