.recipe-img {
  width: 100%;
}

.user-card {
  border-radius: 12px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;

  .user-backdrop {
    top: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 16px;
    border-radius: 12px;
  }

  img {
    max-height: 100%;
  }

  .edit-icon {
    color: white;
    background: green;
    padding: 4px;
    border-radius: 4px;
  }
}