@import "./assets/scss/variables.scss";

.App {
  text-align: center;
  height: 100%;
  background: #f8f8f8;
  color: #171717;
}

html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
}

.myloader{
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.45);
  z-index: 999;
}
.MuiFormControlLabel-root {
  margin: 0;
}

.MuiContainer-root {
  height: 100%;
}
.cursor{
  cursor: pointer;
}
.topButton{ 
  display: flex; 
  align-items: center; 
}
.hidden-file-picker {
  display: none;
}
.appName{
  display: flex;
  align-items: center;
  justify-content: center;
}
.appName span img{
  width: 50px;
  height: auto;
  margin-right: 10px;
}
.MuiButton-root {
  border-radius: 24px !important;
  color: white !important;
  height: 47px !important;
  padding: 0 20px !important;
  font-size: $fontSize14 !important;
  letter-spacing: 1px !important;
  border-bottom: 2px solid rgba(0,0,0,0.05) !important;
  -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,0) !important;
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0) !important;
  padding-top: 2px !important;
}

.MuiButton-root.addNewRecp {
  height: 90px !important;
  border-radius: 13px !important;  
  margin-bottom: 0px !important;
  background: #C4C4C4 !important;
}

.MuiButton-root.addNewRecp svg{
  font-size: 28px;
  margin-right: 5px;
}

.loginBotton .MuiBox-root{
  margin-bottom: 20px !important;
}
.loginBotton .MuiBox-root.marBottom0 {
  margin-bottom: 0px !important;
}
.MuiBox-root.loginOut{
  margin-bottom: 25px !important;
}

.MuiBox-root.noGoogleBt{
  margin-bottom: 0px !important;
}


.MuiButton-outlinedSecondary{
  border: 2px solid #E33E2E !important;
}

.MuiButton-root.Mui-disabled {
  background-color: #17ca23 !important;
}

.MuiInputBase-root {
  border-radius: 24px !important;
}

.MuiButton-outlined.MuiButton-outlinedPrimary {
  color: #40D44A !important;
  border: 1px solid #40D44A !important;
}

.MuiButton-outlined.MuiButton-outlinedSecondary {
  color: #E43E2D !important;
}

.MuiAppBar-root {
  color: white !important;
}
.MuiFormLabel-root{
  font-size: 14px !important;
  top: -4px !important;
  left: 6px !important;
}
.MuiFormHelperText-root.Mui-error { 
  line-height: normal;
}
.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(8px, 18px) scale(0.90) !important; 
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(8px, -6px) scale(0.70) !important; 
  top: 1px !important;
}

.MuiTab-root {
  border: 1px solid #40D44A !important;
}

.MuiTab-root.Mui-selected {
  color: white !important;
  background-color: #40D44A !important;
}

.MuiTab-root:first-of-type {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.MuiTab-root:last-of-type {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.MuiTabs-indicator {
  display: none;
}

.MuiTab-root {
  border: 1px solid #40D44A !important;
}

.primary-font {
  color: #40D44A !important
}

.bg-primary {
  background-color: #40D44A !important
}

.white-font {
  color: #fff;
}

.black-font {
  color: #282828;
}

.gray-font {
  color: #797979;
}

.MuiLink-root {
  cursor: pointer;
}

.cover-banner {
  height: 180px;
}
.MuiTypography-body1{
  font-size: $fontSize15 !important;
}
.MuiOutlinedInput-input {
  border-radius: inherit;
}
.MuiInputBase-input{
  padding: 2px 20px 0 !important;
  height: 43px !important;
  background-color: #f2f2f2 !important;
  border-top: 0px none !important;
  border-left: 0px none !important;
  border-right: 0px none !important;
  border-bottom: 2px solid #eff1ee !important;
  margin-bottom: 10px !important;
  font-size: $fontSize14 !important;
}
.MuiInputBase-input.MuiInputBase-inputMultiline{
  height: auto !important; 
  padding-top: 13px !important;
}
.MuiOutlinedInput-notchedOutline{
  padding: 0 15px !important;
  height: 52px !important;  
  border: 0px none !important;
  top: -6px !important;
}

.MuiOutlinedInput-multiline {
  padding: 0 !important;

  textarea {
    padding: 15px 15px;
  }
}

.bg-white {
  background-color: white;
}

.auth-card {
  width: 100%;
}

.edit-icon {
  font-size: 18px !important;
  margin-top: 4px;
}

.share-icon {
  cursor: pointer;
  user-select: auto;
}

.field-title {
  font-size: 16px !important;
  font-weight: bold !important;
}
.MuiBottomNavigation-root{ bottom: 0 !important; position: fixed;} 
.app-container{ padding-bottom: 70px !important;}
.underline {
  text-decoration: underline;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected {
  border-top: 2px solid #40D44A;
}
.MuiBottomNavigation-root{
  -webkit-box-shadow: 0 -4px 15px 0 rgba(0,0,0,0.08) !important;
  box-shadow: 0 -4px 15px 0 rgba(0,0,0,0.08) !important;
}
.MuiBottomNavigation-root .MuiButtonBase-root .MuiBottomNavigationAction-label{
  display: none;
}
.MuiBottomNavigation-root .MuiButtonBase-root svg{
  font-size: 29px !important;
  margin-top: 2px !important;
}
.cover-content {
  z-index: 1;
  position: relative;
}

.brand-cover-container {
  position: relative;
}

.brand-cover {
  content: "";
  background: url('./assets//images/cover.jpg') center/cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.brand-cover::after {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
h3.pageName{
  position: relative;
  z-index: 2;
  letter-spacing: 1px;
  padding: 50px 0 60px;
  font-weight: $fontWeight500;
  font-size:$fontSize40;
}
.regiLink{
  margin-top: 20px;
}
.topBar h5{
  font-size: 22px;
}
.chefList .MuiAvatar-root{
  border: 2px solid #40D44A;
  height: 90px;
  width: 90px;
}
.chefInfo{
  flex-direction: inherit !important;
  justify-content: space-between;
  align-items: center;
}
.chefList .chefInfo .text-align-right button.MuiButton-root {
  margin-right: 5px;
  height: 39px !important;
  padding: 0 22px !important;
}
.chefList .chefInfo .text-align-right button.MuiButton-root .MuiButton-label{
  padding-top: 4px !important;
  font-size: 13px;
}
.chefList .user-card{
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
}
.chefList{
  padding-bottom: 10px !important;
}

.chefList .user-card .user-backdrop{
  padding-left: 20px !important;
}
.chefName{
  font-size: $fontSize16 !important;
  text-align: left;
  margin-bottom: 3px !important;
}
.chefLocation{
  text-align: left;
  margin-top: 3px !important;
}
.chefLocation p{
  line-height: normal !important;
}
.appHeader {
  background: url('./assets//images/cover.jpg') center/cover !important;
  position: relative;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
}
.appHeader::before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.MuiAutocomplete-inputRoot{
  padding: 0px !important;
}
.chefList .HeadOut {
  margin-bottom: 13px !important;
  margin-top: 13px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chefList .HeadOut .MuiButton-root{
  padding: 0px !important;
  text-decoration: underline;
  color: #121212 !important;
  text-transform: none;
  height: auto !important;
  border-bottom: 0px none !important;
}
.chefList .HeadOut .MuiButton-root:hover{
background-color: #f8f8f8 !important;
}
.formDialog .MuiBox-root .MuiBox-root:last-child{
  margin-bottom: 0px !important; 
}
.formDialog .MuiBox-root .MuiInputBase-input{
  margin-bottom: 5px !important; 
}
.formDialog .MuiBox-root .MuiBox-root:last-child .MuiInputBase-input{
  margin-bottom: 0px !important; 
}
.MuiDialogTitle-root {
  padding: 13px 20px !important;
}
.MuiDialogContent-root{
  padding: 8px 20px !important;
}
.MuiDialogActions-root{
  padding: 19px 20px !important;
}
.myProfile .MuiButton-root.smButtom {
  margin-top: 70px !important;
}
.myButton {
  max-width: 350px !important;
  width: 350px !important;
}
.whtInput .MuiInputBase-input{
  background: $bgWhite !important;
  border-bottom: 2px solid #f4f5f4 !important;
}
.mySelect{
  padding: 7px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.mySelectRight{
  padding: 4px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px ;  
  height: 35px;
  width: 35px;
  cursor: pointer;
}
.MuiInputBase-root.mySelect{
  padding: 6px 0px 5px 9px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 40px;
  font-size: 14px !important;
  height: 35px;
  margin-right: 10px;
  .MuiNativeSelect-root{
    margin-bottom: 0 !important;
    padding-left: 5px !important;
    height: 20px !important;
    border-bottom: 0px none !important;
    background: none !important;
    letter-spacing: 0.2px;
    color: #171717 !important;
  }
}
.MuiInput-underline.mySelect:after,
.MuiInput-underline.mySelect::before{
  display: none !important;
}
.mySelectNew {
  background: $bgWhite !important; 
  width: 100%;
  border-bottom: 2px solid #f4f5f4 !important;
  text-align: left;
  line-height: 37px !important;
}
.mySelectNew.MuiInput-underline:before,
.mySelectNew.MuiInput-underline:after{ border: 0px none !important;}

.mySelectNew .MuiSelect-select.MuiSelect-select{    
  margin-bottom: 0 !important;
  background: none !important;
  border: 0px none !important;
}

.MuiTabs-flexContainer .MuiTab-root{
  background: $bgWhite;
  color: $green;
}
.accountOut .field-title{
  color: #525351;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.editOut{
  min-width: auto !important;
}
.subPriceOut{
  margin: 15px 0 5px;
}
.subPrice{
  font-weight: $fontWeight500 !important;
  font-size: 16px !important;
}
.subPrice span{
  margin-left: 14px;
  background: #fff !important;
  padding: 12px 25px;
  border-radius: 30px;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
}

.MuiDialogContent-root p.MuiTypography-root img{
  max-width: 100%;
  height: auto;
}
.tikIcon svg{
  font-size: 19px !important;
  color: #fff;
}
.shareIcon svg{
  font-size: 23px !important;
  color: #fff;
  margin-top: -2px;
}
.editIcon{
  cursor: pointer;
}
.editIcon:hover{
  background: #2c9433 !important;
}
.combButt{margin: 15px 0 5px !important;}
.combButt .MuiButton-label{ justify-content: space-between; display: flex; }
.combButt .MuiButton-label .MuiTypography-body1{font-weight: 500; font-size: 14px; letter-spacing: 0.7px;}
.combButtInn { letter-spacing: 0.7px; justify-content: space-between; display: flex; } 
.combButtInn div:first-child{ margin-right: 5px;}
.flexDiv{ justify-content: space-between; display: flex; padding: 15px 20px !important; }
.totalDiv{padding: 15px 20px !important; text-align: left; }
.totalDiv h6{ margin-bottom: 5px;}
.totalDivFlex{justify-content: space-between; display: flex;}
.addiFeeOut{justify-content: space-between; display: flex; margin-bottom: 5px;  color: #40D44A;}
.addiFeeOut .MuiTypography-body1{ font-weight:600 ;}
.totalRight{ min-width: 80px; margin-left: 10px; text-align: right;}
.toalDivFlex .MuiTypography-body1{ font-size: 14px; line-height: auto;}
.toalFree.MuiTypography-body1{ font-size: 12px !important;color: rgba(0, 0, 0, 0.54);}
.difficultyOut .MuiTypography-body1{ font-weight: 600;}
.difficultyOut .MuiTypography-body1 span{ font-weight: 400;}
.difficulty{ margin-left: 20px !important;}
.difficulty span{ margin-left: 7px;}
.timeOut{ display: flex;}
.timeOut p:first-child{ margin-right: 15px;}
/*tags page*/
.tagsOut{ margin-top: 35px; text-align: left;font-family: "Roboto", "Helvetica", "Arial", sans-serif;}
.tagsOut p.greyFont{ color: #858484; margin: 15px 0 !important;}
.tagsOut p.tagsTitle{ color: #000; font-weight: 500; margin-bottom: 12px; font-size: 17px !important;}
.myTags{ display: block; margin-bottom: 10px;}
.myTags span{ display: inline-block; margin-right: 10px; margin-bottom: 10px; border-radius:5px; padding: 10px 20px; background: #ccc; color: #fff; font-weight: 500; cursor: pointer;}
.myTags span:hover{ background: #40D44A;}
.myTags span.selectedTag{ background: #40D44A;}
.myTags span.deselectedTag{ background: #ccc;}
.tagButton{ width: 100%; color: #fff; background: #40D44A !important; margin-top:20px !important; margin-bottom:10px !important}
/*terms page*/
.termsOut .tableOut{ overflow: auto; width: 100%; display: block;}
.termsOut table{ margin-bottom: 30px; margin-top: 30px;}
.termsOut table tr th:first-child{ min-width: 150px;}
.termsOut table tr th:nth-child(2){ min-width: 250px;}
.termsOut table thead{ background: #ddd;}
.termsOut table, .termsOut td, .termsOut th { border: 1px solid #c1c1c1;}
.termsOut td, .termsOut th{ padding: 10px;}
.termsOut tr:nth-child(even) { background: #fff;}
.termsOut table { width: 100%; border-collapse: collapse;}
.termsOut{ margin-top: 35px; text-align: left;font-family: "Roboto", "Helvetica", "Arial", sans-serif;}
.termsOut h6{ margin-bottom: 5px; font-size: 19px !important;}
.subHeadTerms{ color: #40D44A; font-size: 18px !important;}
.termsOut p{ margin-bottom: 10px;}
.termsectHeading { 
  padding-bottom: 10px;
  font-weight: 500 !important;
  font-size: 28px !important;
  letter-spacing: 0.2px;
}
.lastUpdate {
  margin-bottom: 0px !important; padding-bottom: 0px !important;
}
.termHead{
  padding-bottom: 0;
  padding-top: 25px;
  font-weight: 500 !important;
  font-size: 20px !important;
  letter-spacing: 0.2px;
}
.lastUpdate{
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #40D44A !important;
  letter-spacing: 0.2px;
}
.termsBox em{
  margin-bottom: 10px;
  margin-top: 8px;
  display: block;}
.termsBox{ 
  display: block;
  font-size: 15px;
}
.termsBox ul li{ 
  margin-bottom: 15px;
}
.termsBox a{
  display: block;
  padding-bottom:7px;
  color: #171717;
  text-decoration: underline !important;
  cursor: pointer;
}
.termsBox a:hover{
  color: #40D44A !important;
}
.termsBox a.textLink{
  display: inline-block;
  color: #40D44A !important;
}
.termsBox a.textLink:hover{
  color: #121212 !important;
}
.termsText{ display: block; padding-top: 25px; font-size: 14px; letter-spacing: normal;}
.termsText a{ font-weight: bold; color: #000;}
.pinOut{
  display: flex;
}
.recipe-likes{ 
  height: 22px;
  display: flex;
  align-items: center;
  color: $green;
  .MuiTypography-root{
    margin-left: 3px !important;
  }
  .userLike{
    display: flex;
    font-size: 20px;
    align-items: center;
    height: 22px;
    .MuiBox-root{
      height: 22px;
    }
    .MuiButton-root{
      height: 22px;
      float: left;
    }
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text{ 
    padding: 0 !important; 
    height: auto !important;
    color: #40D44A !important;
    font-weight: 400 !important;
    min-width: 30px !important;
    border: 0px none !important;
    background: none !important;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text .MuiButton-label{  
    font-size: 20px !important;
    height: 22px !important;
  }
  .userCmt{
    display: flex;
    align-items: center;
    margin: 0 14px 0px 6px;
    font-size: 20px;
    height: 22px !important;
    .MuiBox-root{
      height: 22px;
    }
    .MuiButton-root{
      height: 22px;
    }
  }
  .userCmt .MuiButton-root{
    margin-left: 0 !important;
  }
  .userCmt.noRightSP{
    margin-right: 0px; 
  }
  .rightSP{
    margin-right: 5px;
  }
  .hideLike{
    display: none;
  }
  p{ margin-left: 1px; font-size: 14px;}
}
.proLikeOut{
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.recipeLikesProfile{
  position: absolute;
  bottom: 14px;
  right: 0px;
  height: 22px;
  align-items: center;
}
.recipeLikesProfile span{     
  margin-left: 4px;
  margin-bottom: -1px;
  letter-spacing: 0px;
  font-size: 14px;
  line-height: 15px;
}
.fiterOut{
  display: flex;
  p{
    padding: 6px 15px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 40px;
    font-size: 14px !important;
    height: 35px;
    cursor: pointer;   
    letter-spacing: 0.2px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
  }
}
.MuiMenuItem-root{
  min-height: 27px !important;
  font-size: 14px !important;
  padding: 5px 13px !important;
}
.filterModal{
  padding: 25px 20px 20px;
  background-color: #fff;
  color: #000; 
  width: 500px;
  max-width: 94%;
  max-height: 80%;
  overflow: auto;
  margin: 0px auto;
  border-radius: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  top: 10%;
  position: relative;
  -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.4);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.4);
}
.modalClose{
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.filterModal h5{ 
  margin-bottom: 20px !important;
  text-align: center;
}
.filterTags{
  display: block;
  text-align: center;
}
.filterModal:focus-visible{
  outline: none;
}
.filterTags span{
  display: inline-block;
  padding: 5px 15px;
  margin: 0 3px;
  border-radius: 30px;
  background-color: #ebebeb;
  border: 1px solid #ccc;
  margin-bottom: 13px;
  cursor: pointer;
}
.filterTags span:hover{ 
  background-color: #fff;
  border: 1px solid #40D44A;
  color: #40D44A; 
}
.filterTags span.selectedTag{ 
  background-color: #40D44A;
  border: 1px solid #40D44A;
  color: #fff; 
} 
.recCardInTop{ 
  color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 13px 14px;
  display: flex;
  align-items: center;
  .userIcon{
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
}
.innerTitle{
  padding: 0 !important;
}
.innerTitle h2 span{
  display: inline-block;
  margin-left: 10px;
  font-size: 15px;
  font-weight: normal;
}
.greyBox{
  background-color: #f2f2f2!important;
  border: 0!important;
  border-bottom: 2px solid #eff1ee!important;
  border-radius: 23px !important;
  margin-top: 18px;
  padding: 0 2px 0;
  position: relative;
}
.greyBoxTop{
  position: absolute;
  top: 13px;
  right: 10px;
  z-index: 9;
}
.greyBox .MuiInputBase-input {
  border-bottom: 0px none !important;
}
.greyBoxTop .MuiSwitch-root {
  margin-top: -7px;
  margin-Right: -7px;
}
.greyBox .MuiFormControl-root{
  display: block;
}
.greyBox .MuiFormControl-root .MuiInputBase-root{
  display: block;
}
.greyBox .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input{
  width: calc(100% - 40px) !important;
  background: none !important;
  border-bottom: 1px solid #e4e4e4  !important;
}
.greyBox .MuiFormControl-root .MuiInputBase-root.MuiInput-underline:before,
.greyBox .MuiFormControl-root .MuiInputBase-root.MuiInput-underline:after{
  display: none;
}
.greyBox button.MuiIconButton-root{ 
left: 50%;
margin-left: -24px;
width: 48px;
}

.otherLinksbt .MuiButton-root{
  width: 100%;
  margin-bottom: 15px;
}
.otherLinksbt a{
  width: 100%;
  margin-bottom: 20px;
  display: block;
  border-radius: 40px;
  height: 47px;
  line-height: 47px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #40D44A;
  text-align: center;
  color: #fff;
}
.otherLinksbt a:hover {
  background-color: rgb(44, 148, 51);
}
.shareProfile {
  position: absolute;
  top: -70px;
  right: 171px;
  display: flex;
  align-items: center;
}
.nosubCrbed {
  position: absolute;
  top: -70px;
  right: 0;
  display: flex;
  align-items: center;
} 
.shareText{
  display: inline-block;
  margin-right: 5px;
}
.smButtomLink {
  width: 47px !important;
  min-width: 47px !important;
  margin-right: 15px !important;
}
.previreButt h2{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.previreButt h2 .MuiButton-root{
  height: 35px !important;
  padding: 0 17px !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
}
.lockRecipe{
  position: absolute;
  top: 40px;
  left: 50%;
  width: 100px;
  margin-left: -50px;
  color: #ddd; 
  text-align: center;
}
.lockRecipe .MuiSvgIcon-root{
  font-size: 90px;
}
// Comments page
.cmtBox{
  background: #fff;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
  margin-bottom: 20px;
}
.cmtBoxTop{
  display: flex;
}
.cmtBoxBott{
  padding-top: 5px !important; 
}
.paddingLeft{
  padding-left: 48px;
}
.cmtUser{
  color: #171717;
  font-weight: 700 !important;
}
.cmtDate{
  font-size: 12px !important;
  color: #858484;
}
.sendbtn.MuiButtonBase-root{
  border: 0px none !important;
}
.sendbtn .MuiSvgIcon-root{
  font-size: 20px !important;
  margin-right: 7px;
  margin-top: -2px;
}
.cmtTextfild{
  width: 100%;
  margin-bottom: 15px !important;
}
.repyCount{
  font-size: 13px;
  color: #a7a7a7;
}
.repyCount em{
  font-style: normal;
}

.cmtTextfild .MuiInput-root{
  padding: 0;
}
.cmtTextfild .MuiInputBase-input.MuiInputBase-inputMultiline{
  padding: 15px !important;
  background: #f7f7f7 !important;
  margin-bottom: 0px !important;
  border-bottom: 0px none !important;
  border-radius: 10px;
}
.cmtTextfild .MuiInput-underline:before,
.cmtTextfild .MuiInput-underline:after{
  display: none; 
}
.linkText:hover{
  color: #111111;
}
.cmtBox .linkText{
  font-weight: 500;
  margin: 5px 0 0 0;
  display: inline-block; 
}
.showReply{ 
  margin-top: 10px; 
  display: none;
}
.replyWrapper .showReply{
  display: block;
}
.showReply .MuiAvatar-root{
  width: 31px;
  height: 31px;
}
.showReply .cmtBoxBott{
  padding-top: 0px !important;
  color: #7c7c7c;
  margin-bottom: 0;
  margin-top: 3px;
  padding-right: 10px;
}
.singlerply{
  margin-bottom: 15px;
}
.showReply .cmtUser{
  font-size: 13px !important;
}
.allcmts{
  max-height: 200px;
  overflow: auto;
  margin-bottom: 15px;
} 
.allcmts .singlerply:last-child{
  margin-bottom: 0;
}
.showReply .cmtDate{
  font-size: 11px !important;
} 
.shareOut{ 
  position: relative !important;
}
.userNameSpc{
  margin-top: 5px;
  margin-bottom: 5px;
}
.pageContainer {
  position: relative;
}
.sbcrBtn{
  position: absolute;
  right: 24px;
  top: -70px;
  z-index: 9;  
  text-align: right;
}
.sbcrBtn .smButtom {
  float: right; 
}
.btn-loader {
  position: absolute;
  width: calc(100% - 2px) !important;
  height: 100%;
  background: rgba(255,255,255,0.55);
  top: 1px;
  left: 1px;
  border-radius: 34px;
  align-items: center;
  justify-content: center; 
  display: flex;
}  
.MuiTypography-body1.diffcultyError{
  text-align: left;
  color: #f44336;
  font-size: 13px !important;
  margin-bottom: 0;
  margin-top: 12px;
  line-height: normal;
  position: relative;
  left: 13px;
  max-width: calc(100% - 13px) !important ;
}
.custFlex{
  display: flex;
}
.custFlexInnLeft{
  width: 50%;
  padding-right: 7px;
}
.custFlexInnRight{
  width: 50%;
  padding-left: 7px;
}

@media (min-width:801px) {
  .auth-card {
    background-color: white;
    -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
    padding: 35px;
    border-radius: 20px;
    width: 600px;
  }
}

@media (max-width:1024px) {
  h3.pageName{
    font-size:$fontSize30;
  }
}


@media (max-width:800px) {
  .termsectHeading { 
    font-size: 24px !important;} 
  h3.pageName{
    font-size:$fontSize30;
  }
  .regiLink{
    margin-top: 0;
  }
  .topBar h5 {
    font-size: 18px;
  }
  .appName span img{
    width: 36px;
    height: auto;
    margin-right: 6px;
}
.appName h5{
  font-size: 20px !important;
}
}

@media (max-width:767px) {
  h3.pageName{
    font-size:$fontSize28;
    padding: 50px 0 90px;
  }
  .MuiButton-root.smButtomLink {
    width: 34px !important;
    height: 33px !important;
    min-width: 33px !important;
    margin-right: 8px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .auth-card {
    padding: 10px;
  }
  .tikIcon svg{
    font-size: 18px !important;
  }
  .shareIcon svg{
    font-size: 18px !important;
  }
  .loginBotton {
    padding: 0 0;
  }
  .loginTop{
    margin-bottom: 0px !important;
  }
  .MuiTypography-body1{
    font-size: $fontSize14 !important;
  }
  .MuiInputBase-input { 
    height: 38px !important;
    color: #7A7A7A !important;
  }
  .MuiButton-root { 
    height: 42px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    height: 46px !important;
  }
  .loginBotton .MuiBox-root {
    margin-bottom: 15px !important;
  }
  .loginBotton{ 
    margin-bottom: 0px !important;
  }
  .setPassBotton,
  .loginTop { 
    margin-bottom: 0px !important;
  }
  .MuiBox-root.loginOut { 
    margin-bottom: 20px !important;
  }
  .MuiBox-root.noGoogleBt{
    margin-bottom: 0px !important;
  }
  .chefList .MuiAvatar-root {
    border: 2px solid #40D44A;
    height: 70px;
    width: 70px;
  }
  .chefList .user-card .user-backdrop {
    padding-left: 15px !important;
  }
  .chefList .chefInfo .text-align-right button.MuiButton-root {
    margin-right: 0;
    height: 33px !important;
    padding: 0 15px !important;
  }
  .chefList .chefInfo .text-align-right button.MuiButton-root .MuiButton-label {
    font-size: 12px;
  }
  .chefName {
    font-size: 15px !important;
    margin-bottom: 2px !important;
  }
  .subPrice span{ 
    padding: 12px 20px;
  }
  // .MuiBox-root-18 {
  //   margin-top: 30px !important;
  //   margin-bottom: 10px !important;
  // }
  .MuiTab-root{
    min-height: 42px !important;
  }
  .sectHeading {
    font-size: 20px !important;
  }
  
  .chefList {
    padding-bottom: 0px !important;
  }
  .MuiButton-root.smButtom {
    height: 33px !important;
    padding: 3px 11px 0px 12px !important;
    font-size: 12px !important;
  }
  .MuiButton-root.smButtom.editOut {
    height: 38px !important;
    width: 38px !important;
    padding: 3px 14px 0px 13px !important;
    font-size: 14px !important;
  }
  .MuiButton-root.smButtom .edit-icon{
    font-size: 14px !important;
    margin-top: 5px;
    margin-right: -2px;
  }
  .MuiButton-root.smButtom.editOut .edit-icon{
    font-size: 16px !important;
    margin-top: 5px;
    margin-right: -2px;
  }
  .recipe-card img.recipe-card-img{
    border-radius: 8px!important;
    height: 100% !important;
  }
  .accountOut .field-title{ 
    margin-top: -10px;
  }
  .userNameSpc{
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .tagsOut { margin-top: 20px;}
  .tagsOut p.greyFont { margin: 7px 0 !important;}
  .tagsOut p.tagsTitle { margin-bottom: 7px; font-size: 15px !important; }
  .myTags span{ margin-right: 8px; margin-bottom: 8px; border-radius:5px; padding: 8px 15px; background: #ccc; color: #fff; font-weight: 500; cursor: pointer;}
  .tagButton{  margin-top:6px !important; margin-bottom:12px !important; }
  .termsOut{ margin-top: 20px; padding-bottom: 15px !important; }
  .termsOut h6 { margin-bottom: 5px; }
  .filterModal{ padding: 20px 20px 20px; }
  .filterModal h5 { margin-bottom: 15px !important; font-size: 17px; }
  .modalClose { right: 15px; top: 15px; }
  .filterTags span { display: inline-block; padding: 4px 10px;  margin-bottom: 10px; font-size: 12px;}
  .modalClose .MuiSvgIcon-root{font-size: 20px;}
  .cmtDate { font-size: 12px !important; }
  .cmtBox { padding: 17px; }
  .cmtBoxBott { padding-top: 0px !important; }
  .cmtBox{ margin-bottom: 15px;}
  .shareText{display: none;}
  .shareProfile { right: 130px;}
  .nosubCrbed { right: 0;}
  .shareProfile .MuiButton-root.smButtom{padding: 0; min-width: 33px; width: 34px; padding: 3px 5px 0px 5px !important;}
  .nosubCrbed .MuiButton-root.smButtom{padding: 0; min-width: 33px; width: 34px; padding: 3px 5px 0px 5px !important;}
}
@media (max-width:600px) {
  .termsectHeading { 
    font-size: 22px !important;
  }
  .MuiDialog-paper {
    margin: 20px !important;
  }
  .MuiDialog-paperFullWidth {
    width: calc(100% - 40px) !important;
  }
  .MuiDialog-paperScrollPaper { 
    max-height: calc(100% - 40px) !important;
  }
  .recipeTop{
    margin-top: 25px;
  }
  .shareProfile { 
    top: -54px; 
  } 
  .nosubCrbed { 
    top: -54px; 
  } 
  .lockRecipe .MuiSvgIcon-root{
    font-size: 70px;
  }
  .termsOut table{ margin-bottom: 20px; margin-top: 20px;}
  .termHead { 
    padding-top: 15px;
  } 
  .recipeLikesProfile{
    bottom: 10px;
  }
  .recipe-nameplate .recipe-name{
    padding-bottom: 10px !important;
  }
  .termsBox { 
    font-size: 14px;
  }
  .termsOut h6 {
    font-size: 17px !important;
  }
  .termsOut ul{ padding-left: 15px;}
  .myProfile .MuiButton-root.smButtom {
    margin-top: 80px !important;
  }
  .myButton {
    max-width: 100% !important;
    width: 100% !important;
  }
  .acbalance h6{
    font-size: 16px !important;
  }
  .appName span img{
    width: 30px;
    margin-right: 6px;
  }
  .appName h5{
    font-size: 17px !important;
  }
  .appName{
    margin-top: -2px !important;
  }
  .subPriceOut {
    margin: 15px 0 8px;
  }
  .shareOut{
    display: block !important;
    position: relative !important;
  }
  .fiterOut .MuiInputBase-root.mySelect:first-child{
    margin-left: 0; 
  }
  .fiterOut p {
    padding: 6px 12px; 
  }
  .sbcrBtn {
    top: -54px;
  }
  .sbcrBtn {
    right: 16px;
  }
  .shareProfile {
    right: 130px;
  }
  .nosubCrbed {
    right: 0;
  }
}

@media (max-width:479px) {
  .MuiBottomNavigation-root .MuiButtonBase-root svg {
    font-size: 25px !important; 
  }
  .MuiContainer-root { 
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .sbcrBtn {
    right: 13px;
  }
  .MuiInputBase-input{
    padding: 2px 15px 0 !important;
  }
  .chefList .MuiAvatar-root {
    border: 2px solid #40D44A;
    height: 55px;
    width: 55px;
  }
  .sectHeading {
    font-size: 17px !important;
  }
  .chefList .chefInfo .text-align-right button.MuiButton-root .MuiButton-label {
    font-size: 11px;
  }
  .chefList .chefInfo .text-align-right button.MuiButton-root {
    height: 30px !important;
    padding: 0 14px !important;
    min-width: auto;
  } 
  .chefList .chefInfo .text-align-right button.MuiButton-root .MuiButton-label {
    padding-top: 3px !important;
  }
  .chefName { 
    margin-bottom: 0px !important;
  }
  .chefLocation .MuiTypography-body1 {
    font-size: 13px !important;
  }
  .chefList .user-card {
    margin-bottom: 10px !important;
  }
  .chefList .HeadOut {
    margin-bottom: 10px !important;
  }
  .myProfile .MuiButton-root.smButtom {
    margin-top: 45px !important;
  }
  .subPriceOut {
    margin: 10px 0 13px;
  }
  .subPrice span {
    padding: 12px 16px;
    margin-left: 10px;
  }
  .profileOut .makeStyles-large-23 {
    height: 100px !important;
    width: 100px !important;
    margin-left: -4px;
  }
  .MuiButton-root.smButtomLink { 
    margin-right: 7px !important;
  }
  .MuiInputBase-input{
    font-size: 13px !important;
  }
}

@media (max-width:350px) {
  .MuiInputBase-input {
    padding: 2px 20px 0 !important;
  }
  .shareProfile {
    right: 127px;
  }
  .nosubCrbed {
    right: 0;
  }
  .MuiButton-root.smButtomLink {
    margin-right: 5px !important;
  }
  .timeOut p {
    font-size: 13px !important;
  }
  .timeOut p:first-child {
    margin-right: 12px;
  }
  .custFlex{
    display: block !important;
  }
  .custFlexInnLeft{
    width: 100%;
    padding-right: 0;
  }
  .MuiTypography-body1.diffcultyError{
    margin-top: 5px;
  }
  .custFlexInnRight{
    width: 100%;
    padding-left: 0;
    margin-top: 5px !important;
    margin-Bottom: 15px !important;
  }
}






 

