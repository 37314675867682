@import "../../assets/scss/variables.scss";
@import "../../assets/scss/allModal.scss";

.cover-img {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.myRecipe{
    align-items: flex-end;
    display: flex !important;
    justify-content: flex-end;
}
.myRecipe .smButtom{    
    margin-bottom: 30px;
} 
.addIngredient {
    position: relative;
    padding-right: 40px !important;
}
.addIngredient .ingrediAmt .MuiInputBase-input{
    border-radius: 23px 0 0 23px !important;
    padding-right: 0 !important;
}
.addIngredient .ingrediName .MuiInputBase-input{
    border-radius: 0 0 0 0 !important; 
}
.ingrediAmt{
    width: 90px;
}
.ingrediName{
    width: calc(100% - 92px);
    margin-left: 1px !important;
    margin-right: 0 !important;
}
.addIngredient .MuiFormControl-fullWidth .MuiInputBase-root{
    border-radius: 24px 0 0 24px !important;
}
.addIngredient .MuiIconButton-root{
    background: #cccccc !important;
    color: $bgWhite;
    padding: 10px !important ;
    border-radius: 0 25px 25px 0 !important ;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    height: calc(100% - 11px);
}

.addIngredient .MuiIconButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.5);
}
 
.addIngredient .MuiIconButton-root svg.MuiSvgIcon-root { 
    width: 20px !important;
    height: auto !important;
}

@media (max-width:600px) {

    .addIngredient .ingrediAmt .MuiInputBase-input{ 
        padding-left: 15px !important;
    }
    .addIngredient .ingrediName .MuiInputBase-input{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .ingrediAmt{
        width: 85px;
    }
    .ingrediName{
        width: calc(100% - 87px); 
    }
}