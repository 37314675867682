.Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
}

form.checkout-form {
    padding: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02) !important;
}
.checkout-form .MuiButton-fullWidth {
    max-width: 180px !important;
}
.ElementsApp .InputElement{
    font-size: 12px !important;
}
.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 13px 14px;
    border-radius: 20px;
    background: #f8f8f8;
}

.StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.card-error {
    text-align: left;
}

.card-error{
    color: red;
}

@media (max-width:600px) {
    .checkout-form .MuiButton-fullWidth {
        max-width: 100% !important;
    }
}