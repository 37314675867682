@import "../../assets/scss/variables.scss";
@import "../../assets/scss/allModal.scss";

.cover-img {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.counter {
    align-items: center;
    height: 28px;
    width: 28px;
    background: #40D44A;
    color: white;
    text-align: center;
}

.recipe-img-container {
    margin: 0.25rem;
    width: 30%;
    max-height: 100px;
    border-radius: 4px;
    img {
        border-radius: 12px;
        max-height: 100%;
        object-fit: contain;
    }
}
.cover-banner.recipeImage{
    height: 280px;
    position: relative;
}
.recipeOwner{
    display: flex; 
    color: #fff;
    align-items: center;
    padding-top: 13px;
    padding-left: 15px;
    padding-bottom: 70px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}
.userIcon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
}
.editButtonNew{
    margin-right: 1px;
}
.MuiButton-root.editButton{
    background: $bgWhite !important;
    padding: 0px !important;
    height: 30px !important;
    min-width: 30px !important;
    border: 1px solid $green !important;

}
.MuiButton-root.deleteButton{
    background: $bgWhite !important;
    padding: 0px !important;
    height: 30px !important;
    min-width: 30px !important;
    border: 1px solid $colorRed !important;
}
.editButton span{ 
    color: $green !important;
    font-size: 16px;
}
.deleteButton span{
    color: $colorRed !important;
    font-size: 14px;
}
.iconButtonOut{
    display: flex;
    align-items: center;
}
.recipeTop{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}
.recipeTop h5{ 
    font-weight: 500 !important;
}
.instructionsList .counter{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
}


@media (max-width:600px) {
    .cover-banner.recipeImage{
        height: 200px !important;
    }
    .cover-banner.recipeImage .cover-img{
        height: 100% !important;
    }
     
    .recipeTop h5{ 
        font-size: 20px;
        line-height: normal;
    }
    .MuiTypography-h6 {
        font-size: 18px !important;
    }
    .instructionsSpace{
        padding-left: 14px !important;
    }
}

@media (max-width:479px) {
    .MuiTypography-h6 {
        font-size: 17px !important;
    }
    .recipeTop h5{ 
        font-size: 19px;
    }
}