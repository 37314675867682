
.app-container {
	height: calc(100% - 120px);
    margin-bottom: 56px;
    overflow-y: auto;
}

@media (max-width:599px) {
    .app-container {
        height: calc(100% - 113px);
    }
}