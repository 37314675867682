@import "../../assets/scss/variables.scss";
@import "../../assets/scss/allModal.scss";

.recipe-img {
  width: 100%;
}

.recipe-card {
  border-radius: 12px;
  position: relative;
  height: 200px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;

  img.recipe-card-img {
    max-height: 100%;
    // aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  .edit-icon {
    color: white;
    background: green;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.recipe-nameplate {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.6));
  position: absolute;
  text-align: left;
  width: 100%;
  bottom: 0;
  height: 100%;
  border-radius: 12px;
  .recipe-time {
    color: white;
    padding: 0 !important;
  }
  .recipe-name {
    color: white;
    padding: 13px 14px !important;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start; 
  }
  .recCardIn{
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: end;
    position: absolute;
    bottom: 0;
  }
  .recCardInTop{ 
    color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 13px 14px;
    display: flex;
    align-items: center;
    .userIcon{
      margin-right: 10px;
      width: 40px;
      height: 40px;
    }
  }
 
  .cardInnerBx{
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 100%;
  }
}
