@import "../scss/variables.scss";
.notesModal {
	.modal-dialog {
		max-width: 704px;
		width: 90%;
	}
	.modal-body {
		padding: 0px;
		.notesView {
			.titleRejection {
				font-family: $secondary-font-family;
				font-weight: bold;
				font-size: 20px;
				line-height: 21px;
				text-align: center;
				color: #000000;
				margin: 0px;
				padding: 40px 0 8px 0;
			}
			.joyclub-close-radius {
				position: absolute;
				right: 10px;
				top: 10px;
				cursor: pointer;
				font-size: $fontSize24;
				color: $colorGreyBlack;
			}
			.notTextScroll {
				height: 452px !important;
				ul {
					margin: 0px;
					padding: 0 10px 0 39px;
					list-style: none;
					li {
						width: 100%;
						float: left;
						padding: 0px 0 0 22px;
						margin: 24px 0 0;
						position: relative;
						border-bottom: 1px solid $colorGrey;
						p {
							&::before {
								content: "";
								width: 6px;
								height: 6px;
								background-color: $colorGreen;
								position: absolute;
								border-radius: 50%;
								left: 0px;
								top: 6px;
							}
							float: left;
							padding: 0px 10px 0px 0px;
							text-align: justify;
							margin: 0px;
						}
						.timetxt {
							width: 100%;
							float: left;
							text-align: right;
							font-weight: normal;
							font-size: 10px;
							line-height: 18px;
							color: #888888;
							padding: 8px 0;
						}
						.pdfBtn {
							font-weight: normal;
							font-size: $fontSize14;
							line-height: 21px;
							color: $colorBlack;
							border: 1px solid $colorGreen;
							border-radius: 4px;
							padding: 4px 8px;
							display: flex;
							align-items: center;
							justify-content: center;
							float: left;
							margin-top: 16px;
							cursor: pointer;
							.joyclub-download-radius {
								font-size: $fontSize24;
								color: $colorDarkPink;
								margin-left: 12px;
							}
						}
					}
				}
			}
		}
	}
}

.editDetailsModal {
	.modal-dialog {
		max-width: 824px;
		width: 94%;
		margin: 0 auto;
		height: 100%;
		.modal-body {
			display: flex;
			padding: 40px 50px 0;
		}
	}
}

.rejectModal {
	.modal-dialog {
		max-width: 704px;
		width: 94%;
		margin: 0 auto;
		.modal-body {
			display: flex;
			padding: 30px 20px;
			.RejectionNote {
				width: 100%;
			}
		}
	}
}

.reviewModal {
	.modal-dialog {
		max-width: 704px;
		width: 94%;
		margin: 0 auto;
		.modal-content {
			border: none;
			.modal-body {
				display: flex;
				padding: 0;
				position: relative;
				.closeBtn {
					font-style: normal;
					font-weight: normal;
					font-size: 15px;
					line-height: 120%;
					color: #FFFFFF;
					outline: none;
					cursor: pointer;
					position: absolute;
					top: -23px;
					margin: 0;
				}
			}
		}
	}
}

.digitCardModal {
	.modal-dialog {
		max-width: 480px;
		width: 94%;
		margin: 0 auto;
		.modal-content {
			border: none;
			.modal-body {
				display: flex;
				padding: 0;
				position: relative;
				.closeBtn {
					font-style: normal;
					font-weight: normal;
					font-size: 15px;
					line-height: 120%;
					color: #FFFFFF;
					outline: none;
					cursor: pointer;
					position: absolute;
					top: -23px;
					margin: 0;
				}
			}
		}
	}
}

.ViewMap {
	.modal-dialog {
		max-width: 1024px;
		width: 94%;
		margin: 0 auto;
		height: 100%;
		.modal-body {
			padding: 0;
			.mapSection {
				width: 100%;
				float: left;
				border-radius: 8px;
				.map {
					height: 700px;
					border-radius: 6px;
				}
			}
		}
	}
}

.venueHoursModal {
	.modal-dialog {
		max-width: 450px;
		width: 94%;
		margin: 0 auto;
		.modal-body {
			display: flex;
			padding: 40px 40px;
			.venueHoursMain {
				p {
					padding: 0px;
				}
				.venueHoursRow {
					.formSection {
						ul {
							padding: 22px 0 0;
							li {
								padding: 10px 0px;
							}
						}
					}
				}
			}
			
		}
	}
}

.EditDealModal {
	.modal-dialog {
		max-width: 704px;
		width: 94%;
		margin: 0 auto;
		min-height: 100%;
		.modal-body {
			display: flex;
			padding: 40px 24px 0;
		}
	}
}

.AddVenueModal {
	.modal-dialog {
		max-width: 560px;
		width: 94%;
		margin: 0 auto;
		.modal-content {
			border-radius: 8px;
		}
		.modal-body {
			display: flex;
			padding: 0px;
		}
	}
}

.generateCodeModal {
	.modal-dialog {
		max-width: 944px;
		width: 94%;
		margin: 0 auto;
		height: 100%;
		.modal-content {
			border-radius: 0px;
			border: none;
			background-color: transparent;
		}
		.modal-body {
			display: flex;
			padding: 0px;
			background-color: $bgWhite;
			border-radius: 6px;
		}
	}
}

.retireeLogin {
	.modal-dialog {
		max-width: 464px;
		.modal-content {
			width: 100%;
			padding: 25px;
			margin: 0 auto;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
			border: none;
			.modal-body {
				padding: 15px 5px;
				.formbox {
					.mainForm {
						.titleText {
							font-size: $fontSize24;
							line-height: 32px;
							color: $colorGreyBlack;
							margin: 0 0 30px;
							text-align: center;
						}
						.textField {
							margin-bottom: 20px;
						}
						.dobInput {
							margin-bottom: 20px;
							.react-datepicker-wrapper {
								.react-datepicker__input-container {
									input {
										border-color: $colorLightGreen;
									}
								}
							}
							.ageError {
								color: $colorGreyBlack;
								font-size: $fontSize14;
							}
						}
						.linkRow {
							width: 100%;
							margin: 20px 0 36px 0;
							display: flex;
							align-items: center;
							justify-content: space-between;
						}
						.submitBtn {
							height: 48px;
						}
						.forgotLink {
							.text {
								font-size: $fontSize14;
								color: $colorGreen;
								font-weight: normal;
								line-height: 24px;
							}
						}
					}
					.custBtn {
						margin-top: 24px;
					}
					.memberLogin {
						margin-top: 20px;
					}
				}
			}
			.footerSection {
				padding-top: 33px;
				.links {
					font-weight: normal;
					line-height: 24px;
					color: $colorGreen;
					font-size: $fontSize16;
					text-align: center;
					padding-bottom: 47px;
					display: block;
					i {
						padding-right: 4px;
					}
					&:last-child {
						padding-bottom: 0;
						text-align: left;
						color: $colorBlack;
						font-size: $fontSize16;
					}
					.pinkD {
						color: $colorDarkPink;
					}
				}
				&.backLogin {
					padding-top: 28px;
					.links {
						font-size: $fontSize16;
						color: $colorGreen;
						text-align: center;
						font-weight: normal;
						position: relative;
						i {
							padding-right: 10px;
							position: absolute;
							top: 2px;
							left: 114px;
						}
					}
				}
			}
		}
	}
	&.getMembership {
		.modal-dialog {
			max-width: 944px;
			.modal-content {
				width: 100%;
				padding: 0;
				border: none;
				.modal-body {
					padding: 0;
					.membershipModal {
						position: relative;
						.closeBtn {
							font-style: normal;
							font-weight: normal;
							font-size: 15px;
							line-height: 120%;
							color: #FFFFFF;
							outline: none;
							cursor: pointer;
							position: absolute;
							top: -23px;
							margin: 0;
						}
						.mainPart {
							width: 100%;
							float: left;
							display: flex;
							position: relative;
							.leftPart {
								width: 50%;
								float: left;
								position: relative;
								.modalScroll {
									height: 517px !important;
									.paddingBox {
										padding: 60px 38px 0;
									}
									.radioSection {
										border-top: 1px solid $colorGrey;
										padding-left: 75px;
										padding-top: 20px;
										margin-top: 48px;
										.labelTitle {
											padding-bottom: 20px;
											margin: 0;
											font-size: $fontSize12;
											font-weight: normal;
											color: $colorGreyBlack;
										}
										.mainBox {
											display: flex;
											.RadioBtnMain {
												.radio-label {
													color: $colorGreyBlack;
													font-size: $fontSize14;
													line-height: 22px;
													cursor: pointer;
													margin: 0;
													span {
														color: $colorGreyBlack;
														font-size: $fontSize16;
														line-height: 24px;
													}
												}
												input[type="radio"]+.radio-label:before {
													top: 0
												}
											}
											&:nth-child(2) {
												.RadioBtnMain {
													padding-right: 44px;
												}
											}
										}
									}
									.footerSection {
										position: relative;
										margin-bottom: 20px;
										padding-top: 48px;
										.submitBtn {
											text-transform: capitalize;
											width: 404px;
											border-radius: 4px;
											margin: 0 auto;
											height: 48px;
										}
									}
								}
							}
							.rightPart {
								width: 50%;
								float: left;
								padding: 0 40px;
								background: $bgWhite;
								border-top-left-radius: 6px;
								border-bottom-left-radius: 6px;
								border-right: 1px solid $colorGrey;
								.title {
									font-weight: bold;
									font-size: $fontSize24;
									line-height: 32px;
									color: $colorGreyBlack;
									font-family: $secondary-font-family;
									margin: 0px;
									padding: 60px 0 12px 0;
									text-align: left;
								}
								p {
									font-weight: normal;
									font-size: $fontSize16;
									line-height: 150%;
									color: $colorGreyBlack;
									padding-bottom: 32px;
								}
								.memberImg {
									img {
										max-width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.selectCategoryModal {
		.modal-dialog {
			max-width: 944px;
			.modal-content {
				width: 100%;
				padding: 0;
				border: none;
				.modal-body {
					padding: 0;
					.membershipModal {
						position: relative;
						.closeBtn {
							font-style: normal;
							font-weight: normal;
							font-size: 15px;
							line-height: 120%;
							color: #FFFFFF;
							outline: none;
							cursor: pointer;
							position: absolute;
							top: -23px;
							margin: 0;
						}
						.mainPart {
							width: 100%;
							float: left;
							display: flex;
							position: relative;
							.leftPart {
								width: 50%;
								float: left;
								position: relative;
								.modalScroll {
									height: 517px !important;
									// height: calc(100vh - 510px)!important;
									.paddingBox {
										padding: 60px 38px 0;
									}
									.radioSection {
										border-top: 1px solid $colorGrey;
										padding-left: 75px;
										padding-top: 20px;
										margin-top: 48px;
										.labelTitle {
											padding-bottom: 20px;
											margin: 0;
											font-size: $fontSize12;
											font-weight: normal;
											color: $colorGreyBlack;
										}
										.mainBox {
											display: flex;
											.RadioBtnMain {
												.radio-label {
													color: $colorGreyBlack;
													font-size: $fontSize14;
													line-height: 22px;
													cursor: pointer;
													margin: 0;
													span {
														color: $colorGreyBlack;
														font-size: $fontSize16;
														line-height: 24px;
													}
												}
												input[type="radio"]+.radio-label:before {
													top: 0
												}
											}
											&:nth-child(2) {
												.RadioBtnMain {
													padding-right: 44px;
												}
											}
										}
									}
									.footerSection {
										position: relative;
										margin-bottom: 20px;
										padding-top: 48px;
										.submitBtn {
											text-transform: capitalize;
											width: 404px;
											border-radius: 4px;
											margin: 0 auto;
											height: 48px;
										}
									}
								}
							}
							.rightPart {
								width: 50%;
								float: left;
								padding: 0 40px;
								background: $bgWhite;
								border-top-left-radius: 6px;
								border-bottom-left-radius: 6px;
								border-right: 1px solid $colorGrey;
								.title {
									font-weight: bold;
									font-size: $fontSize24;
									line-height: 32px;
									color: $colorGreyBlack;
									font-family: $secondary-font-family;
									margin: 0px;
									padding: 60px 0 12px 0;
									text-align: left;
								}
								p {
									font-weight: normal;
									font-size: $fontSize16;
									line-height: 150%;
									color: $colorGreyBlack;
									padding-bottom: 32px;
								}
								.memberImg {
									img {
										max-width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.messageModal {
		.modal-dialog {
			.modal-content {
				padding: 60px 40px 50px 40px;
				.modalInner {
					.modal-body {
						padding: 0;
					}
				}
			}
		}
	}
	&.referCode {
		.modal-dialog {
			.modal-content {
				padding: 40px;
				.modalInner {
					.modal-body {
						padding: 0;
					}
				}
			}
		}
	}
	&.locationModal {
		.modal-dialog {
			.modal-content {
				padding: 40px;
				.modalInner {
					.modal-body {
						padding: 0;
					}
				}
			}
		}
	}
	&.contactModal {
		.modal-dialog {
			.modal-content {
				padding: 40px;
				padding-top: 30px;
				.modalInner {
					.modal-body {
						padding: 0;
					}
				}
			}
		}
	}
	&.selectCategoryModal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					.membershipModal {
						.mainPart {
							.rightPart {
								.title {
									padding: 40px 0 12px 0;
								}
							}
							.leftPart {
								.selectCatSection{
									.modalScroll{
										.paddingBox {
										padding: 40px 24px 0 8px;
									}
									}
								}
								.modalScroll {
									// height: 650px !important;
									.paddingBox {
										padding: 40px 24px 0;
									}
								}
							}
						}
					}
				}
				.footerSection {
					padding: 0;
					padding-bottom: 8px;
					text-align: center;
					display: table;
					padding-top: 32px;
					.submitBtn {
						width: 404px;
						border-radius: 4px;
						font-family: $primary-font-family;
						text-transform: none;
					}
				}
			}
		}
	}
	&.uploadImgSection {
		.modal-dialog {
			.modal-content {
				padding: 56px 30px 8px 30px;
				.modal-body {
					padding: 0;
					.codeSection {
						.fileUpload{
							.dropzoneCustom {
								border: none;
								background: $colorGrey;
								height: 171px;
								width: 181px;
								margin: 0 auto;
									margin-top: 40px;
									position: relative;
										&:before{
			content: '\e936';
				font-family: 'JoyClub';
				font-size: $fontSize20;
				color: $colorLightGrey;
				position: absolute;
				font-weight: 600;
				top: 40%;
				left: 43%;		
					}
		&:after{
			content: '';
				width: 48px;
				height: 48px;
				border:1px solid;
				border-radius:50%;
				border:2px solid $colorLightGrey;
				position: absolute;
				top: 35%;
				left: 35%;
					}
							}
						}
						.titleText {
							margin: 0 auto !important;
							font-size: $fontSize24;
							line-height: 32px;
							position: relative;
							width: fit-content;
							color: $colorGreyBlack;
							margin: 0;
							font-family: $secondary-font-family;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}

.timelineModal {
	.modal-dialog {
		max-width: 1180px;
		width: 96%;
		.modal-body {
			padding: 0px;
		}
		.timelineRow {
			width: 100%;
			float: left;
			padding: 0 10px 26px;
			h1 {
				padding: 20px 0px 16px;
				font-size: $fontSize24;
				line-height: 32px;
				color: $colorGreyBlack;
				text-align: left;
				margin: 0px;
			}
			ul {
				margin: 0px;
				padding: 60px 0px;
				width: 100%;
				display: flex;
				white-space: nowrap;
				overflow-x: auto;
				position: relative;
				overflow-y: hidden;
				li {
					float: left;
					list-style: none;
					padding: 0 24px 0 0px;
					margin: 0 0px 0 0;
					position: relative;
					text-align: left;
					display: flex;
					.boxcricle {
						position: inherit;
						&::before {
							content: "";
							position: absolute;
							left: 0px;
							top: 17px;
							width: 16px;
							height: 16px;
							background: $bgDarkPink;
							border-radius: 50%;
						}
						span {
							font-weight: normal;
							font-size: 14px;
							line-height: 24px;
							color: $colorGreyDark;
							.subscriptionTxt {
								color: $colorGreyBlack;
							}
						}
					}
					&::before {
						content: "";
						position: absolute;
						left: 0px;
						top: 50%;
						width: 100%;
						height: 1px;
						background: $bgBlack;
					}
					&:nth-child(odd) {
						align-items: flex-start;
						&::after {
							top: 30px;
						}
						.boxcricle {
							span {
								position: relative;
								top: 60px;
							}
						}
					}
					&:nth-child(even) {
						&::after {
							bottom: 30px;
						}
						.boxcricle {
							span {
								position: relative;
								bottom: 60px;
								// color: $colorGreyBlack;
							}
						}
					}
					&::after {
						content: "";
						position: absolute;
						left: 7px;
						width: 1px;
						height: 22px;
						background: $bgDarkPink;
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.editDetailsModal {
		.modal-dialog {
			.modal-body {
				display: flex;
				padding: 40px 24px 0;
			}
		}
	}
}

@media only screen and (min-width:992px) and (max-width:1024px) {
	.editDetailsModal {
		.modal-dialog {
			.modal-body {
				display: flex;
				padding: 40px 24px 0;
			}
		}
	}
}

@media only screen and (min-width:768px) and (max-width:991px) {
	.editDetailsModal {
		.modal-dialog {
			.modal-body {
				display: flex;
				padding: 40px 24px 0;
			}
		}
	}
	.retireeLogin {
		&.getMembership {
			.modal-dialog {
				max-width: 625px;
				.modal-content {
					.modal-body {
						.membershipModal {
							.mainPart {
								.rightPart {
									padding: 0 20px;
								}
								.leftPart {
									.modalScroll {
										.footerSection {
											.submitBtn {
												width: 265px;
											}
										}
										.radioSection {
											padding-left: 40px;
											.mainBox {
												flex-direction: column;
												&:nth-child(2) {
													.RadioBtnMain {
														padding-right: 0px;
														padding-top: 10px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.selectCategoryModal {
			.modal-dialog {
				.modal-content {
					.modal-body {
						.membershipModal {
							.mainPart {
								.rightPart {
									padding: 0 20px;
								}
								.leftPart {	
									form{
										.formSection {
											.footerSection{
											.submitBtn {
												width: 265px;
											}
										}
									}
								}
								}
							}
						}
					}
				}
			}
		}
		&.comPreModal{
		.modal-dialog {
			.modal-content {
				.modal-body {
					.membershipModal {
						.mainPart{
							 .rightPart{
								  .title{
									  font-size: $fontSize18;
									  padding-top: 52px;
								  }
							 }
							 .comPreferences{
								 .modalScroll{
									 .paddingBox{
										 .radioPart{
											 .mainBox{
												 width: 100%;
												 display: table;
												 .RadioBtnMain{
													 width: 50%;
													 float: left;
													 padding-bottom: 10px;
													 &:nth-child(3){
													label{
														padding-right: 0;
													}
													
												}
												 }
												
											 }
										 }
									 }
								 }
							 }
							 .footerSection{
								 .submitBtn{
									 width: 265px;
								 }
							 }
						}
					}
				}
			}
		}
		}
	}
}

@media only screen and (min-width:576px) and (max-width:767px) {
	.editDetailsModal {
		.modal-dialog {
			.modal-body {
				display: flex;
				padding: 40px 24px 0;
			}
		}
	}
	.retireeLogin {
		&.getMembership {
			.modal-dialog {
				max-width: 550px;
				.modal-content {
					.modal-body {
						.membershipModal {
							.mainPart {
								flex-direction: column;
								.rightPart {
									padding: 0 20px;
									width: 100%;
									border-top-right-radius: 6px;
									.txt{
										padding-bottom: 30px;
									}
									.title {
										font-size: $fontSize16;
									}
									.memberImg {
										width: 200px;
										margin: 0 auto;
										img {
											width: 100%;
										}
									}
								}
								.leftPart {
									width: 100%;
									.modalScroll {
										.footerSection {
											    padding-top: 40px;
											.submitBtn {
												width: 265px;
											}
										}
										.radioSection {
											padding-left: 40px;
											.mainBox {
												flex-direction: column;
												&:nth-child(2) {
													.RadioBtnMain {
														padding-right: 0px;
														padding-top: 10px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		
		&.selectCategoryModal {
			.modal-dialog {
				.modal-content {
					.modal-body {
						.membershipModal {
							.mainPart {
								.rightPart {
									padding: 0 20px;
								}
								.leftPart {	
									form{
										.formSection {
											.footerSection{
											.submitBtn {
												width: 250px;
											}
										}
									}
								}
								}
							}
						}
					}
				}
			}
		}
		&.comPreModal{
		.modal-dialog {
			.modal-content {
				.modal-body {
					.membershipModal {
						.mainPart{
							 .rightPart{
								  .title{
									  font-size: $fontSize18;
									  padding-top:40px;
								  }
							 }
							 .comPreferences{
								 .modalScroll{
									 .paddingBox{
										//  .radioPart{
										// 	 .mainBox{
										// 		 width: 100%;
										// 		 display: table;
										// 		 .RadioBtnMain{
										// 			 width: 50%;
										// 			 float: left;
										// 			 padding-bottom: 10px;
										// 			 &:nth-child(3){
										// 			label{
										// 				padding-right: 0;
										// 			}
													
										// 		}
										// 		 }
												
										// 	 }
										//  }
									 }
								 }
							 }
							 .footerSection{
								 .submitBtn{
									 width: 265px;
								 }
							 }
						}
					}
				}
			}
		}
		}
	}
}

@media only screen and (max-width:575px) {
	.retireeLogin {
		.modal-dialog {
			margin: 0 auto;
			max-width: 390px;
		}
		&.signUp {
			.customCalender {
				.react-datepicker-popper {
					.react-datepicker {
						.react-datepicker__month-container {
							.react-datepicker__day-names {
								.react-datepicker__day-name {
									width: 38px;
								}
							}
							.react-datepicker__month {
								.react-datepicker__week {
									.react-datepicker__day {
										width: 38px;
									}
								}
							}
						}
						.react-datepicker__header {
							.react-datepicker-wrapper {
								width: 30%;
							}
						}
					}
				}
			}
		}
		&.referCode {
			.modal-dialog {
				.modal-content {
					padding: 40px 30px 40px 30px;
					.modal-body {
						.codeSection {
							.imgSection {
								width: 160px;
								padding-bottom: 10px;
								img {
									width: 100%;
								}
							}
							.titleText {
								font-size: $fontSize18;
							}
						}
					}
				}
			}
		}
		&.locationModal {
			.modal-dialog {
				.modal-content {
					padding: 30px 16px 16px 20px;
					.modal-body {
						.codeSection {
							.imgSection {
								width: 160px;
								padding-bottom: 10px;
								img {
									width: 100%;
								}
							}
							.titleText {
								font-size: $fontSize18;
							}
							.form{
								.locationSection{
									input{
										padding-left: 10px;
										&::-webkit-input-placeholder {
												font-size: $fontSize14;
											}

											&::-moz-placeholder {
												font-size: $fontSize14;
											}

											&:-ms-input-placeholder {
											font-size: $fontSize14;
											}

											&:-moz-placeholder {
												font-size: $fontSize14;
											}
									}
									.locateIcon{
										right: 10px;
										

									}
								}
							}
						}
					}
				}
			}
		}
		&.contactModal {
			.modal-dialog {
				.modal-content {
					padding: 30px 16px 16px 20px;
					.modal-body {
						.codeSection {
							.imgSection {
								width: 160px;
								padding-bottom: 10px;
								img {
									width: 100%;
								}
							}
							.titleText {
								font-size: $fontSize18;
							}
							.form{
								.locationSection{
									input{
										padding-left: 10px;
										&::-webkit-input-placeholder {
												font-size: $fontSize14;
											}

											&::-moz-placeholder {
												font-size: $fontSize14;
											}

											&:-ms-input-placeholder {
											font-size: $fontSize14;
											}

											&:-moz-placeholder {
												font-size: $fontSize14;
											}
									}
									.locateIcon{
										right: 10px;
										

									}
								}
							}
						}
					}
				}
			}
		}
		&.getMembership {
			.modal-dialog {
				max-width: 400px;
				margin: 50px 0 30px;
				.modal-content {
					.modal-body {
						.membershipModal {
							.mainPart {
								flex-direction: column;
								.rightPart {
									padding: 0 20px;
									width: 100%;
									border-top-right-radius: 6px;
									.txt{
										padding-bottom: 30px;
									}
									.title {
										font-size: $fontSize16;
									}
									.memberImg {
										width: 172px;
										margin: 0 auto;
										img {
											width: 100%;
										}
									}
								}
								.leftPart {
									width: 100%;
									.modalScroll {
										.footerSection {
											padding-top: 40px;
											.submitBtn {
												width: 265px;
											}
										}
										.radioSection {
											padding-left: 40px;
											.mainBox {
												flex-direction: column;
												&:nth-child(2) {
													.RadioBtnMain {
														padding-right: 0px;
														padding-top: 10px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
			&.selectCategoryModal{
			 .modal-dialog{
				  .modal-content {
					  .modal-body {
						  .membershipModal{
							  .mainPart{
								  .leftPart{
									  .modalScroll{
										//   height: 400px !important;
										  .selectCatSection{
											  .paddingBox{
												  .activityUl{
													    
												  }
											  }
										  }
									  }
								  }
							  }
						  }
					  }
					  .footerSection {
						  .submitBtn{
							  width: 250px;
						  }
					  }
				  }
			 }
		}
			&.comPreModal{
		.modal-dialog {
			.modal-content {
				.modal-body {
					.membershipModal {
						.mainPart{
							 .rightPart{
								  .title{
									  font-size: $fontSize18;
									  padding-top:40px;
								  }
							 }
							 .comPreferences{
								 .modalScroll{
									 .paddingBox{
										 .radioPart{
											 .mainBox{
												 width: 100%;
												 display: table;
												//  .RadioBtnMain{
												// 	 width: 50%;
												// 	 float: left;
												// 	 padding-bottom: 10px;
												// 	 &:nth-child(3){
												// 	label{
												// 		padding-right: 0;
												// 	}
													
												// }
												//  }
												
											 }
										 }
									 }
								 }
							 }
							 .footerSection{
								 .submitBtn{
									 width: 265px;
								 }
							 }
						}
					}
				}
			}
		}
		}
	}
}

@media only screen and (min-width:360px) and (max-width:399px) {
	.retireeLogin {
		.modal-dialog {
			margin: 0 auto;
			max-width: 350px;
		}
		&.signUp {
			.customCalender {
				.react-datepicker-popper {
					.react-datepicker {
						.react-datepicker__month-container {
							.react-datepicker__day-names {
								.react-datepicker__day-name {
									width: 36px;
								}
							}
							.react-datepicker__month {
								.react-datepicker__week {
									.react-datepicker__day {
										width: 36px;
									}
								}
							}
						}
						.react-datepicker__header {
							.react-datepicker-wrapper {
								width: 30%;
							}
						}
					}
				}
			}
		}
		&.getMembership {
			.modal-dialog {
				max-width: 380px;
				.modal-content {
					.modal-body {
						.membershipModal {
							.mainPart {
								flex-direction: column;
								.rightPart {
									padding: 0 20px;
									width: 100%;
									border-top-right-radius: 6px;
									.title {
										font-size: $fontSize16;
									}
									.memberImg {
										width: 172px;
										margin: 0 auto;
										img {
											width: 100%;
										}
									}
								}
								.leftPart {
									width: 100%;
									.modalScroll {
										.footerSection {
											.submitBtn {
												width: 265px;
											}
										}
										.radioSection {
											padding-left: 40px;
											.mainBox {
												flex-direction: column;
												&:nth-child(2) {
													.RadioBtnMain {
														padding-right: 0px;
														padding-top: 10px;
													}
												}
											}
										}
									}
								}
							}
						}
						
					}
				}
			}
			
		}
	
	&.messageModal {
		.modal-dialog {
			.modal-content {
				padding: 40px 30px 40px 30px;
				.messageMain {
					.titleText {
						font-size: $fontSize16;
					}
				}
			}
		}
	}
	&.referCode {
		.modal-dialog {
			.modal-content {
				padding: 40px 30px 40px 30px;
				.modal-body {
					.codeSection {
						.imgSection {
							width: 150px;
							padding-bottom: 10px;
							img {
								width: 100%;
							}
						}
						.titleText {
							font-size: $fontSize18;
							line-height: 25px;
						}
					}
				}
			}
		}
	}
	&.locationModal {
		.modal-dialog {
			.modal-content {
				padding: 30px 16px 16px 20px;
				.modal-body {
					.codeSection {
						.imgSection {
							width: 150px;
							padding-bottom: 10px;
							img {
								width: 100%;
							}
						}
						.titleText {
							font-size: $fontSize18;
							line-height: 25px;
						}
					}
				}
			}
		}
	}
	&.contactModal {
		.modal-dialog {
			.modal-content {
				padding: 30px 16px 16px 20px;
				.modal-body {
					.codeSection {
						.imgSection {
							width: 150px;
							padding-bottom: 10px;
							img {
								width: 100%;
							}
						}
						.titleText {
							font-size: $fontSize18;
							line-height: 25px;
						}
					}
				}
			}
		}
	}
	&.selectCategoryModal{
			 .modal-dialog{
				  .modal-content {
					  .modal-body {
						  .membershipModal{
							  .mainPart{
								  .leftPart{
									  .modalScroll{
										  .selectCatSection{
											  .paddingBox{
												  .activityUl{
												  }
											  }
										  }
									  }
								  }
							  }
						  }
					  }
					  .footerSection {
						  .submitBtn{
							  width: 250px;
						  }
					  }
				  }
			 }
		}
}
}
@media only screen and (min-width:320px) and (max-width:359px) {
	.retireeLogin {
		.modal-dialog {
			margin: 0 auto;
			max-width: 300px;
			.modal-content {
				.form {
					.titleText {
						font-size: $fontSize16;
						margin-bottom: 30px;
					}
					.checkboxMain {
						label {
							font-size: $fontSize12;
						}
					}
					.forgotLink {
						.text {
							font-size: $fontSize12;
						}
					}
					.dobInput {
						.ageError {
							font-size: $fontSize12;
						}
					}
				}
				.footerSection {
					.links {
						padding-bottom: 25px;
						font-size: $fontSize14;
						&:last-child {
							font-size: $fontSize14;
						}
					}
				}
			}
		}
		&.signUp {
			.customCalender {
				.react-datepicker-popper {
					.react-datepicker {
						width: auto;
						.react-datepicker__month-container {
							.react-datepicker__day-names {
								.react-datepicker__day-name {
									width: 30px;
									font-size: $fontSize12;
								}
							}
							.react-datepicker__month {
								.react-datepicker__week {
									.react-datepicker__day {
										width: 30px;
										font-size: $fontSize12;
									}
								}
							}
						}
						.react-datepicker__header {
							.react-datepicker-wrapper {
								width: 32%;
								.react-datepicker__input-container {
									.custom-input {
										font-size: $fontSize12;
									}
								}
							}
						}
					}
				}
			}
		}
		&.messageModal {
			.modal-dialog {
				.modal-content {
					padding: 40px 30px 40px 30px;
					.messageMain {
						.titleText {
							font-size: $fontSize16;
							line-height: 25px;
						}
					}
				}
			}
		}
		&.referCode {
			.modal-dialog {
				.modal-content {
					padding: 40px 20px 40px 20px;
					.modal-body {
						.codeSection {
							.imgSection {
								width: 138px;
								padding-bottom: 10px;
								img {
									width: 100%;
								}
							}
							.txt {
								padding-bottom: 20px;
							}
							.titleText {
								font-size: $fontSize16;
								line-height: 25px;
							}
						}
					}
				}
			}
		}
		&.locationModal {
			.modal-dialog {
				max-width: 300px;
				.modal-content {
					padding: 30px 16px 16px 20px;
					.modal-body {
						.codeSection {
							.imgSection {
								width: 138px;
								padding-bottom: 10px;
								img {
									width: 100%;
								}
							}
							.txt {
								padding-bottom: 20px;
							}
							.titleText {
								font-size: $fontSize16;
								line-height: 25px;
							}
						}
					}
				}
			}
		}
		&.contactModal {
			.modal-dialog {
				max-width: 300px;
				.modal-content {
					padding: 30px 16px 16px 20px;
					.modal-body {
						.codeSection {
							.imgSection {
								width: 138px;
								padding-bottom: 10px;
								img {
									width: 100%;
								}
							}
							.txt {
								padding-bottom: 20px;
							}
							.titleText {
								font-size: $fontSize16;
								line-height: 25px;
							}
						}
					}
				}
			}
		}
		&.digitCardModal{
			 .mainPart {
				 .retireeDetails{
					     padding: 0 10px 32px 10px;
				 }
			 }
		}
	}
}