@import "../../assets/scss/variables.scss";

.textField {
	padding: 0;
	width: 100%;
	display: block;
	position: relative;
	margin-bottom: 16px;

	.inputBox {
		position: relative;

		.icon-css {
			color: $colorGreyBlack;
			position: absolute;
			top: 15px;
			right: 18px;
			font-size: $fontSize14;
			background: transparent;
			font-style: inherit;
			&.eye-show{
				&::before{
					content: "Show";
				}
			}
			&.eye-hide{
				&::before{
					content: "Hide";
				}
			}
		}
		.eye-hide,
		.eye-show {
			cursor: pointer;
		}
		.errorMsg {
			display: block;
			font-size: $fontSize14;
			text-align: left;
			padding: 0;
			margin: 0;
			color: $colorWhite;
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.5s ease-out;
			
		}
		.errorMsg.show {
			color: $colorRed;
			padding: 8px 0 0;
			max-height: 50px;
			transition: max-height 0.5s ease-in;

			display: flex;
			align-items:flex-start;
			.joyclub-Info{
				font-size: $fontSize20;
				color: $colorRed;
				margin-right: 6px;
			}
			.joyclub-Check{
				font-size: $fontSize20;
				color: $colorGreen;
				margin-right: 6px;
			}
			p{
				margin: 0px;
				padding: 0px 0 0 0;
				font-size: $fontSize14;
				color: $colorRed;
			}
			&.passwordcustom{
				flex-direction: column;
				max-height: 100px;
				transition: max-height 0.5s ease-in;
				padding: 2px 0 0;
				.red{
					display: flex;
					padding: 2px 0;
				}
				.green{
					display: flex;
					padding: 2px 0;
					span{
						margin: 0px;
						padding: 0px 0 0 0;
						font-size: $fontSize14;
						color: $colorGreen;
					}
				}
			}
		}
	}

	.form-control {
		background: $bgWhite;
		color: $colorGreyBlack;
		font-size: $fontSize14;
		font-weight: 400;
		padding: 12px 16px 12px 16px;
		text-overflow: ellipsis;
		height: auto;
		outline: 0px;
		width: 100%;
		border-radius: 4px;
		border: 1px solid $colorLightGreen;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		&::-webkit-input-placeholder {
			color: $colorGreyDark;
		}

		&::-moz-placeholder {
			color: $colorGreyDark;
		}

		&:-ms-input-placeholder {
			color: $colorGreyDark;
		}

		&:-moz-placeholder {
			color: $colorGreyDark;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: $colorLightGreen;
		}

		&.error {
			border-color: $colorRed;
		}
	}

	.form-group {
		margin-bottom: 0px;
	}
}
