@import "../../assets/scss/variables.scss";
@import "../../assets/scss/allModal.scss";

.cover-img {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.myProfile{
    align-items: center;
    display: flex !important;
    justify-content: space-between;
}
.myProfile .submitBtn {
    margin-top: 84px;
}
.profileOut .MuiBox-root{
    margin: 0px;
}
.TopSpace{
    margin-top: 30px !important;
}

@media (max-width:479px) {
    .myProfile .submitBtn {
        margin-top: 40px;
    }
    .TopSpace{
        margin-top: 20px !important;
    }
}